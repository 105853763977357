import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "diagnostics" }
const _hoisted_2 = {
  key: 0,
  class: "card card-border mb-5"
}
const _hoisted_3 = {
  class: "card-header card-header-jaya border-warning",
  role: "button",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#unfinishedDiagnostics",
  "aria-expanded": "true",
  "aria-controls": "unfinishedDiagnostics"
}
const _hoisted_4 = {
  class: "card-body border-top",
  id: "unfinishedDiagnostics"
}
const _hoisted_5 = { class: "card card-border" }
const _hoisted_6 = { class: "card-header card-header-jaya" }
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = { class: "card-body border-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card_title_jaya = _resolveComponent("card-title-jaya")!
  const _component_DiagnosticsList = _resolveComponent("DiagnosticsList")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.getDiagnostics.filter((diagnostic) => !diagnostic.finished).length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_card_title_jaya, {
              "duotune-icon": "general/gen012",
              title: "Mes diagnostics en cours",
              subTitle: "A terminer pour visualiser les résultats"
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_DiagnosticsList, {
              selectable: false,
              finished: false
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_card_title_jaya, {
          "duotune-icon": "abstract/abs027",
          title: "Mes diagnostics passés"
        }),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            class: "btn btn-primary btn-sm svg-icon svg-icon-white",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'new-diagnostic' })))
          }, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" }),
            _createTextVNode(" Nouveau diagnostic ")
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_DiagnosticsList, {
          selectable: false,
          finished: true
        })
      ])
    ])
  ]))
}